.root {
  all: unset;
  position: relative;
  flex-shrink: 0;
  appearance: none;
  width: 28px;
  height: 16px;
  padding: 0;
  border: none;
  border-radius: 9999px;
  background: none;
  background-color: var(--accents-6);
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.root[data-state="checked"] {
  background-color: #fff;
}

.thumb {
  display: block;
  width: 12px;
  height: 12px;
  transform: translateX(2px);
  transition: transform 100ms;
  border-radius: 9999px;
  background-color: var(--white);
  will-change: transform;
}

.thumb[data-state="checked"] {
  transform: translateX(14px);
  background-color: var(--red);
}
