@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.root {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.liveIndicator {
  position: relative;
  flex-shrink: 0;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.75rem;
  border-radius: 0.5rem;
  background: #b33828;
}

.liveIndicator:before {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: -1;
  top: -0.375rem;
  left: -0.375rem;
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;
  animation: pulse 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  border-radius: 45px;
  background-color: #b33828;
}

.label {
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: #b33828;
  font-weight: 900;
  letter-spacing: 4%;
  text-transform: uppercase;
}

.text {
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}
