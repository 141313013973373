@keyframes fromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    opacity: translateY(0);
  }
}

@keyframes fromTop {
  from {
    transform: translateY(-100%);
  }
  to {
    opacity: translateY(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    opacity: translateY(0);
  }
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    opacity: translateY(0);
  }
}

.root {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
}

.header {
  display: flex;
  position: relative;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.5rem;
}

.drawer {
  align-self: flex-start;
  height: 100vh;
  overflow-y: auto;
  animation-duration: 0.25s;
  row-gap: 0.5rem;
}

.content {
  padding: 1rem 1.5rem;
}

.close {
  margin-left: auto;
  padding: 8px;
  border: 0;
  border-radius: 24px;
  appearance: none;
}

.root__hasTitle .header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.root__hasTitle .content {
  /* 100% minus header height */
  height: calc(100% - 69px);
}

.root__light .header {
  border-bottom-color: #f2f2f2;
}

.root__light .close {
  background-color: transparent;
  color: #666;
}

.root__light .drawer {
  background-color: #ffffff;
  color: #141414;
}

.root__dark .header {
  border-bottom-color: #262626;
}

.root__dark .close {
  background-color: #333333;
  color: #fff;
}

.root__dark .drawer {
  background-color: #141414;
  color: #ffffff;
}

.root__top {
  align-items: start;
}

.root__top .drawer {
  animation-name: fromTop;
}

.root__right {
  justify-items: end;
}

.root__right .drawer {
  animation-name: fromRight;
}

.root__bottom {
  align-items: end;
}

.root__bottom .drawer {
  animation-name: fromBottom;
}

.root__left {
  justify-items: start;
}

.root__left .drawer {
  animation-name: fromLeft;
}
