.root {
  --btn-size: 32px;
  --col-gap: 8px;
  --columns: var(--mobile-cols, 1.5);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.lens {
  grid-column: 1;
  grid-row: 1;
  margin: 0 0%;
  overflow: hidden;
}

.tray {
  gap: var(--col-gap);
  display: flex;
  flex-direction: row;
  min-width: 280px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  scroll-padding-left: 20px;
}

.tray::-webkit-scrollbar {
  display: none;
}

/* .tray::before,
.tray::after {
  content: "";
  display: block;
  flex: 0 0 5%;
  scroll-snap-align: start;
  width: 5%;
} */

.slide {
  flex-shrink: 0;
  width: calc((100% / 5) - 22px);
  min-width: 180px;
  scroll-snap-align: start;
  margin: 0 var(--col-gap);
}

.controls {
  display: none;
}

/* Tablet */
@media (min-width: 1000px) {
  .root {
    --columns: var(--tablet-cols, var(--default-cols));
  }

  .lens {
    margin: 0;
  }
  .tray {
    margin: 0 var(--col-gap * -1px);
  }
  .tray::before,
  .tray::after {
    display: none;
  }
  .controls {
    display: flex;
    position: relative;
    z-index: 2;
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    justify-content: space-between;
    margin: 0 calc((var(--btn-size) / 2) * -1);
    pointer-events: none;
  }

  .btn {
    position: relative;
    width: var(--btn-size);
    height: var(--btn-size);
    padding: 0;
    border: none;
    border-radius: 100%;
    background: black;
    text-align: center;
    pointer-events: auto;
    appearance: none;
  }

  .light .btn {
    background-color: var(--white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.08);
    color: var(--accents-5);
  }
  .dark .btn {
    background-color: var(--accents-5);
    color: var(--white);
  }
  .btn__prev {
    left: 24px;
    transform: rotate(180deg);
    background: url("/svg/rightArrowPlus.svg");
  }

  .btn__next {
    right: 24px;
    background: url("/svg/rightArrowPlus.svg");
  }

  /* Offset controls based on media aspect ratio */
  /* Intrisic Ratio equation: (h % w) */
  .controls__offset_1x1 {
    --intrisic-offset: 100%;
  }

  .controls__offset_16x9 {
    --intrisic-offset: 56.25%;
  }

  .controls__offset_4x3 {
    --intrisic-offset: 75%;
  }

  .controls__offset_2x3 {
    --intrisic-offset: 150%;
  }

  .controls__offset_2x5 {
    --intrisic-offset: 175%;
  }

  .controls__offset {
    align-self: stretch;
    height: 0;
    padding-bottom: calc(
      (var(--intrisic-offset) / var(--columns)) - calc(var(--col-gap) * 1.5)
    );
  }

  .controls__offset .btn {
    position: absolute;
    top: calc(50% - (var(--btn-size) / 2));
  }

  .controls__offset .btn__prev {
    left: 24px;
    transform: rotate(180deg);
    background: url("/svg/rightArrowPlus.svg");
  }

  .controls__offset .btn__next {
    right: -2px;
    background: url("/svg/rightArrowPlus.svg");
  }
}

/* Desktop */
@media (min-width: 1000px) {
  .root {
    --columns: var(--default-cols);
  }
}

/* HD */
@media (min-width: 1200px) {
  .root {
    --columns: var(--hd-cols, var(--default-cols));
  }
}

.consistentSlideSize.root {
  width: 100%;
}

.consistentSlideSize .lens {
  margin-right: -16px;
  margin-left: -16px;
  padding: 0;
}

.consistentSlideSize .tray::before,
.consistentSlideSize .tray::after {
  flex: 0 0 16px;
  scroll-snap-align: start;
  width: 16px;
}

@media (min-width: 410px) and (max-width: 749px) {
  .consistentSlideSize .root {
    --columns: 2;
  }

  .consistentSlideSize .slide {
    width: auto;
    margin: 0 8px;
  }
}

@media (max-width: 750px) {
  .slide {
    max-width: 220px;
    margin: 0 auto;
    gap: 12px;
  }
  .mobileFullWidthSlide .lens,
  .mobileFullWidthSlide .tray {
    margin: 0;
  }
  .mobileFullWidthSlide .tray::before,
  .mobileFullWidthSlide .tray::after {
    content: none;
  }
}

@media (min-width: 750px) {
  .consistentSlideSize .lens {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
  }
}
