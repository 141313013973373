.electionMaps {
  background-color: #e0e0e0;
  margin: 0 auto;
  padding: 2rem 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  min-width: 330px;
  padding: 0px 5%;
}

.ElectionNightHeader {
  color: #ee252f;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Quincy CF';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: -1px;
  text-transform: uppercase;
  padding-top: 70px;
  display: flex;
  justify-content: center;
}

.ElectionNightHeader span {
  color: #000;
  margin-left: 5px;
}

.presidentialMap {
  display: flex;
  justify-content: center;
  min-height: 100%;
  margin-bottom: 0rem;
}

.presidentialIframe {
  padding: 0 1.5rem;
  margin: 0 auto;
  min-width: 40%;
  max-width: 1000px;
  min-height: 100%;
  overflow: hidden;
  border: none;
  width: 100%;
}

.mapsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.maps {
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  gap: 1rem;
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
}

.map {
  flex: 1;
  min-width: 300px;
}

.mapIframe {
  padding: 0 1.5rem;
  margin: 0 auto;
  width: 100%;
  border: none;
}

.senateIframe {
  padding: 0 1.5rem;
  margin: 0 auto;
  width: 100%;
  border: none;
  height: 100%;
}

.houseIframe {
  padding: 0 1.5rem;
  margin: 0 auto;
  width: 100%;
  border: none;
}

.electionPolicies {
  color: #353535;
  font-family: 'Libre Franklin';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  margin-right: 35%;
}

.electionPolicies span {
  text-decoration: underline;
  text-underline-offset: 1.8px;
}

.ddhqNoticeContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  width: 90%;
}

.ddhqNoticeText {
  color: #353535;
  font-family: 'Libre Franklin';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
}

@media (max-width: 480px) {
  .ddhqNoticeContainer {
    flex-direction: row-reverse;
  }

  .electionPolicies {
    margin-right: 0;
  }
  .maps {
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .electionMaps {
    padding: 0px 0%;
    width: 100%;
  }

  .p-4 {
    padding: 0 !important;
  }
}

@media (min-width: 481px) and (max-width: 995px) {
  .maps {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 641px) {
  .maps {
    width: 100%;
  }
}

.footerContainer {
  min-height: 100%;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 3.5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: -0.14px;
  margin: 0 auto;
  max-width: 1550px;
  min-width: 330px;
  width: 100%;
  background-color: #fff;
}
.divider {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 4%;
  overflow-x: hidden;
  /* margin: 0.1rem 0; */
}
@media (min-width: 640px) {
  .footerContainer {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: 2.2%;
    margin-right: 2.2%;
    padding-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .footerContainer {
    min-height: 350px;
  }
}
.logo {
  width: 170px;
  display: flex;
  margin-top: 1rem;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media (min-width: 1368px) {
  .flexContainer {
    flex-direction: row;
  }
}

.flexBlock {
  margin-bottom: 2rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .flexBlock {
    display: block;
  }
.divider{
  display: none;
}

}

@media (min-width: 1024px) {
  .flexBlock {
    margin-right: 2rem;
  }
}

.linksGrid {
  display: grid;
  flex-shrink: 1;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding-top: 0.75rem;
  text-align: start;
  white-space: nowrap;
}

@media (max-width: 635px) {
  .linksGrid {
    padding: 0 4%;
  }
}

@media (min-width: 640px) {
  .linksGrid {
    gap: 4rem;
  }
}

@media (min-width: 768px) {
  .linksGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 4.5rem;
    text-align: start; 
  }
}

@media (min-width: 1024px) {
  .linksGrid {
    display: flex;
    gap: 5rem;
    padding: 0 2rem;
  }
}
.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
}

@media (min-width: 768px) {
  .linkContainer {
    align-items: flex-start;
  }
}

.columnFlex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-decoration: underline;
  font-size: 14px;
}

.flexColumnAlignment {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

@media (min-width: 768px) {
  .flexColumnAlignment {
    align-items: flex-start;
  }
  
}

.termsAndPrivacy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0rem;
  line-height: 2;
}

@media (min-width: 768px) {
  .termsAndPrivacy {
    flex-direction: row;
    line-height: 1.2;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

@media (min-width: 768px) {
  .copyright {
    justify-content: space-between;
    flex-direction: row;
  }
}

.linkHeader {
  opacity: 0.6;
  font-size: 14px;
  margin-top: 1rem;
}

.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .logoContainer {
    display: block;
  }
}

@media (min-width: 1024px) {
  .logoContainer {
    margin-right: 2rem;
  }
  
}

.podcastContainer {
  margin-left: 5%;
  margin-right: 5%;
  min-width: 330px;
  max-width: 1550px;
  width: 100%;
}

@media (min-width: 1024px) {
  .podcastContainer {
    margin-right: 4%;
    margin-left: 4%;
  }
}

.podcastHeader {
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.125rem;
}

@media (min-width: 1280px) {
  .podcastTitle {
    font-size: 1em;
    margin-bottom: 4rem;
  }
}

.podcastGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 1111px) {
  .podcastGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.podcastCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2.75rem;
  padding: 2.5rem 1rem;
  border-radius: 0.375rem;
  height: 100%;
}

.podcastMorningWireCard {
  background-color: #f6f5ec;
}

.podcastElectionWireCard {
  background-color: #f2e5dc;
}

.podcastImage {
  max-width: 200px;
  object-fit: contain;
}

.podcastTextContainer {
  display: flex;
  flex-direction: column;
}

.podcastPodcastTitle {
  line-height: 1.75;
  font-size: 1.125rem;
  font-weight: 500;
}

@media (min-width: 640px) {
  .podcastPodcastTitle {
    font-size: 1.25rem;
  }
  .podcastCard {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .podcastPodcastTitle {
    font-size: 1.375rem;
  }
}

.podcastDescription {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  max-width: 338px;
  font-size: 1rem;
  line-height: 1.75;
  font-weight: normal;
  letter-spacing: -0.2px;
}

@media (min-width: 768px) {
  .podcastDescription {
    font-size: 1.125rem;
  }
}

@media (min-width: 1024px) {
  .podcastDescription {
    font-size: 1.25rem;
  }
}

.podcastCtaLink {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.75;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-top: auto;
}

@media (min-width: 640px) {
  .podcastCtaLink {
    font-size: 0.875rem;
  }
}

.podcastLinkText {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}
.electionNavContainer {
  background-color: #fff;
  padding: 18px 25px;
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 100%;
  margin: auto;
  border-radius: 50px;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.04), 0px 15px 35px 0px rgba(0, 0, 0, 0.06);
}

.electionNavList {
  list-style-type: none;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.electionNavItem {
  font-size: 14px;
  color: #172e39;
  opacity: 0.56;
  transition: all 0.3s ease-in-out;
}

.electionNavItemActive{
  color: #172E39;
  opacity: 1;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.electionNavItem a {
  text-decoration: none;
  color: #333;
}

.redDot {
  color: red;
  margin-left: 5px;
  opacity: 1;
}

.electionNavItem span {
  opacity: 1;
}

.electionNavBlog {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.electionNavBlog p {
  font-size: 14px;
  color: #172e39;
  opacity: 0.56;
}

.electionNavBlogActive {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.electionNavBlogActive p {
  font-size: 14px;
  color: #172E39;
  opacity: 1;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.electionNavBlog svg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.electionNavBlogActive svg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.svgWrapper {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.svgWrapper svg + svg {
  margin-left: 2px;
}

@media (max-width: 768px) {
  .svgWrapper svg + svg {
    margin-left: -6px;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1000px) {
  .electionNavList {
    flex: 1;
    justify-content: space-evenly;
    padding: 6px 12px;
  }
}

@media (max-width: 768px) {
  .electionNavContainer {
    padding: 8px 25px;
    width: auto;
    max-width: 100%;
    border-radius: 40px;
  }

  .electionNavList {
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }

  .electionNavItem {
    font-size: 16px;
  }

  .electionNavImg svg {
    width: 30px;
    height: 16px;
  }
  .electionNavItemActive {
    font-size: 16px !important;
  }
  .electionNavBlog p {
    font-size: 16px !important;
  }
  .electionNavBlogActive p, .electionNavBlogActive a {
    font-size: 16px !important;
  }
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 42px;
  border: 1px solid #ebebeb;
  background-color: #f2e1d7;
  max-width: 90%;
  width: 1080px;
  height: auto;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04), 0px 64px 68px -48px rgba(0, 0, 0, 0.1);
  padding: 33px 0px 24px 69px;
  margin: 50px 5%;
  overflow: hidden;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60%;
}

.offerText {
  color: #000;
  font-family: 'Libre Franklin';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.message {
  color: var(--GS-Brand-GS-Black, #241d1d);
  font-family: 'Libre Franklin';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.28px;
  max-width: 90%;
  margin-bottom: 25px;
}

.message span {
  font-weight: 700;
}

.ctaLink {
  color: #000;
  font-family: 'Libre Franklin';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  text-decoration-line: underline;
  text-underline-offset: 3.5px;
}

.svgContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
}

.svgItem {
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

.svgItem:not(:last-child) {
  margin-bottom: -1px;
}
@media (max-width: 980px) {
  .bannerContainer {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 20px 15px;
  }

  .message {
    margin: auto;
    font-size: 20px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
  }

  .ctaLink {
    width: 100%;
    max-width: 200px;
    align-self: center;
    font-size: 16px;
    margin-top: 10px;
  }

  .svgContainer {
    opacity: 0;
    visibility: hidden;
  }
}
.voteBannerAnchor {
  padding: 8px;
  display: flex;
  justify-content: center;
}

.voteBanners {
  width: 100%;
}

.voteBannersMobile {
  display: block;
  width: 95%;
}

@media screen and (min-width: 1000px) {
  .voteBannerAnchor {
    padding: 24px 16px;
  }
}