.sectionLabel {
  color: var(--neutrals-95, #0D0D0D);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 18.4px */
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media screen and (max-width: 999px) {
  .sectionLabel {
    font-size: 14px;
  }
}

/* Custom Input Styles */

.container {
  display: block;
  width: 100%;
}
.customInputContainer {
  position: relative;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  background: #F5F5F5;
  border: 1px solid transparent;
  border-radius: 6px !important;
}
.customInputContainerError {
  position: relative;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  background: #F5F5F5;
  border: 1px solid red;
  border-radius: 6px !important;
}
.customInputPlaceholder {
  position: absolute;
  top: 50%;
  left: 20px;
  padding: 0;
  color: #8F8F8F;
  transition: all 0.3s ease;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  box-sizing: border-box;
}
.customInputPlaceholderIsActive {
  position: absolute;
  padding: 0;
  color: #8F8F8F;
  transition: all 0.3s ease;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 2;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  box-sizing: border-box;
  font-size: 12px;
  top: 20px;
  left: 20px;
}
.customInput {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px 20px 0 20px;
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: #140D0E;
  font-family: 'Libre Franklin';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
  border-radius: 6px !important;
}
.customInput:focus {
  outline: none;
}
.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.customInput:-webkit-autofill:active {
	-webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
	-webkit-transition-delay: 9999s;
}

.customInput:-webkit-autofill{
	-webkit-text-fill-color:  #F5F5F5 !important;
  -webkit-box-shadow: 0 0 0 1000px #F5F5F5 inset !important;
}

.customInput:-webkit-autofill{
	-webkit-text-fill-color: #140D0E !important;
}
.customInput::-webkit-inner-spin-button,
.customInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.customInput {
  -moz-appearance: textfield;
}

.offerInputContainer {
  position: relative;
}
.offerInputContainer > p {
  position: absolute;
  bottom: 50% !important;
  top: 43% !important;
  transform: translateY(-50%) !important;
  right: 22px;
  color: black;
  color: var(--Black-Red, #140D0E);
  font-family: 'Libre Franklin';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
  letter-spacing: -0.24px;
}
.errorText {
  position: absolute;
  color: #EB1313;
  font-family: 'Libre Franklin';
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2px;
}

/* Loading Component styles */
.loadingComponent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

@media screen and (min-width: 750px) {
  .loadingComponent {
    background-color: black;
  }
}

/* submit button styles */

.submitButton {
  width: 100%;
  max-width: 290px;
  position: relative;
  background: black !important;
  border: none !important;
  font-family: 'Libre Franklin' !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important; /* 16.5px */
  letter-spacing: -0.3px !important;
  padding: 19px 28px 20px 30px !important;
  border-radius: 64px !important;
  color: #FFF;
  margin: 0 auto;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
}
.submitButton * {
  transition: fill 0.3s ease, stroke 0.3s ease;
}
.submitButton.loading {
  cursor: default;
  background: #D1D1D1 !important;
  color: #8F8F8F !important;
  pointer-events: none;
}
.submitButton.loading * {
  fill: #8F8F8F !important;
  stroke: #8F8F8F !important;
}
.loaderContainer {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: #D1D1D1;
  opacity: 0;
}
.submitButton.loading > .loaderContainer {
  animation: fadeIn 150ms linear forwards;
}
.loaderContainer > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: conic-gradient(rgba(255, 0, 0, 0) 0%, transparent 25% , rgba(255, 0, 0, .05) 26%, rgba(255, 0, 0, 1) 75%, rgba(255, 0, 0, 1) 100%);
  padding: 4px;
  box-sizing: border-box;;
}
.submitButton.loading > .loaderContainer > .loader {
  animation: spin 1s cubic-bezier(0.25,1,0.6,1) infinite;
}
.loaderContainer > .loader::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  z-index: 4;
  border-radius: 50%;
  display: block;
  background: #D1D1D1;
}
form:invalid > .submitButton {
  background: #D1D1D1 !important;
  color: #8F8F8F !important;
  pointer-events: none;
}
form:invalid > .submitButton * {
  fill: white;
  stroke: white;
}
form:invalid > .submitButton * {
  fill: #8F8F8F !important;
  stroke: #8F8F8F !important;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* recurly form */
.twoStack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
@media screen and (min-width: 550px) {
  .twoStack {
    flex-direction: row;
    gap: 8px;
  }
}