* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body,
#__next {
  min-height: 100vh;
}

body {
  min-width: 320px;
  padding-top: 0 !important;
  color: var(--accents-1);
  font-family: 'Libre franklin';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.75;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior-y: none;
}

#lts-banner {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem;
  line-height: 1.25;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 1.5rem 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.hide-mobile {
  display: initial;
}

@media (max-width: 48em) {
  body {
    font-size: 0.9375rem;
  }
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}

/* Mux Player */

.mux-player {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  --media-object-fit: contain;
  --media-object-position: center;
  background-color: #000;
}
.mux-player.hide-seek {
  --seek-backward-button: none;
  --seek-forward-button: none;
}

.mini-player-mode {
  display: flex;
  position: fixed;
  z-index: 10;
  right: 28px;
  bottom: 12px;
  flex-direction: column;
  width: 25vw;
  min-width: 200px;
  max-width: 480px;
  overflow: hidden;
  border-radius: 12px;
}
@media screen and (max-width: 1100px) {
  .mini-player-mode {
    right: calc(50% - 167.5px);
    bottom: 80px;
    width: 100%;
    max-width: 335px;
  }
}
.mini-player-mode.animate-in {
  animation: slideInFromBottom 0.3s ease-out;
}
.mini-player-mode.animate-out {
  animation: slideOutFromBottom 0.15s ease-out forwards;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideOutFromBottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(110%);
  }
}

#pipVideoPlayer {
  position: relative;
  z-index: 999;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pipNewsContainer {
  display: flex;
  z-index: 3;
  align-items: start;
  justify-content: space-between;
  padding: 12px 12px;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #0d2f3a;
  color: white;
}

.pipNewsDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.pipText {
  /* margin-bottom: auto; */
  margin-bottom: 6px ;
  color: #fff;
  font-family: 'Libre Franklin';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.pipButtonNews {
  display: flex;
  z-index: 1;
  align-items: start;
  justify-content: center;
  border: none;
  border-radius: 5px;
  background-color: #0d2f3a;
  color: white;
  cursor: pointer;
}

/* Sided Widget  */
.custom-survey .debate-box {
  border: none !important;
  border-radius: 1rem !important;
  font-family: 'Libre Franklin', sans-serif !important;
}

.custom-survey .sides,
.custom-survey .sides-closed {
  display: flex;
  flex-direction: row !important;
}

.custom-survey .sided-widget,
.custom-survey .debate-holder {
  min-width: 100% !important;
  padding: 0px 25px !important;
}

.custom-survey .top-bar {
  display: none !important;
}

.custom-survey .sided-widget .sides li {
  border: rgba(0, 0, 0, 0.2) 1px solid !important;
}

.custom-survey .h3-link {
  display: flex;
  justify-content: start;
  max-width: 50%;
  margin-bottom: 1rem;
  color: black !important;
  pointer-events: none;
}

.custom-survey .sided-widget h3 {
  color: black !important;
}

.custom-survey .a {
  width: 100%;
  height: 100%;
  background-color: white;
}

.custom-survey .debate-box {
  padding-bottom: 1px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-survey li {
  width: 100% !important;
  border-radius: 0px !important;
}

.custom-survey .more-modal {
  left: inherit !important;
  justify-content: space-around !important;
  height: 300px !important;
}

.custom-survey .more-modal,
.custom-survey .sided-widget .more-modal-action,
.sided-widget .more-modal-title,
.custom-survey .sided-widget .more-modal-powered {
  font-family: 'Libre Franklin', sans-serif !important;
  font-weight: 500 !important;
}

.more-modal {
  z-index: auto !important;
}
.custom-survey .percent {
  position: absolute !important;
  right: 10px !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.custom-survey .text {
  position: relative !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 15x !important;
  font-weight: 400 !important;
}

.custom-survey .vote-icon {
  position: relative !important;
  width: 15px !important;
}

.custom-survey .sided-widget .debate-holder {
  min-height: 0px !important;
}

.custom-survey .h3-link {
  max-width: 90% !important;
  padding-bottom: 1.5rem !important;
  color: black !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32.5px !important;
}

.custom-survey .sided-widget .sides li .a {
  padding: 18px !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-weight: 400 !important;
}

.custom-survey .button-bar {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

.custom-survey .sided-widget .blue-button .full-grow {
  width: 200px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-weight: 400 !important;
}

.custom-survey .share-button {
  top: 0px !important;
}

.a:hover {
  transition: 150ms ease, left 150ms ease;
  background-color: #f4f5f6;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1279px) {
  .custom-survey .sided-widget,
  .custom-survey .debate-holder {
    padding: 0px 0px !important;
  }

  .custom-survey .sided-widget .sides li .a {
    padding: 14px !important;
  }

  .custom-survey .sided-widget,
  .custom-survey .debate-holder {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

@media (max-width: 1023px) {
  .custom-survey .sided-widget .sides li .a {
    padding: 12px !important;
  }

  .custom-survey .sided-widget,
  .custom-survey .debate-holder {
    padding-right: px !important;
    padding-left: px !important;
  }

  .custom-survey .sides,
  .custom-survey .sided-widget,
  .custom-survey .sides-closed {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .custom-survey .sided-widget .sides li .a {
    padding: 10px !important;
  }
  .custom-survey .sided-widget .share-button {
    top: -15px !important;
  }
}

@media (max-width: 639px) {
  .custom-survey .sided-widget .sides li .a {
    padding: 10px !important;
  }

  .custom-survey .xs-nowrap {
    white-space: nowrap;
  }

  .custom-survey .sided-widget .sides li .a {
    font-size: 14px !important;
  }

  .custom-survey .sided-widget,
  .custom-survey .debate-holder {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .custom-survey .h3-link {
    max-width: 100% !important;
    padding-bottom: 1.5rem !important;
    color: black !important;
    font-family: 'Libre Franklin', sans-serif !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 32.5px !important;
    text-align: center !important;
  }
}

@media (min-width: 640px) {
  .underline-center-outward-thin {
    position: relative;
  }

  .underline-center-outward-thin::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 1.5px;
    transition: width 0.3s ease, left 0.3s ease;
    background-color: currentColor;
  }

  .underline-center-outward-thin:hover::after {
    left: 0;
    width: 100%;
  }
}

.sided-widget .main-panel .debate-box.ad-position-bottom {
  padding: 0px !important;
}

.more-modal {
  z-index: 1000 !important;
}

.sides-closed {
  min-height: 4rem !important;
}

.debate-history {
  z-index: 0 !important;
}

.overlay {
  z-index: 0 !important;
}

.blue-button.full-grow {
  width: 50% !important;
  margin: auto !important;
}

@media (max-width: 600px) {
  .blue-button.full-grow {
    width: 100% !important;
    margin: 0 !important;
  }
}

.daily-wire-logo {
  object-fit: contain;
}

.electionNav {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 974px) {
  .electionNav {
    top: 5px;
  }
}

.electionNavFixed {
  position: fixed;
  z-index: 99;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.5s ease;
}

.electionNavFixed.visible {
  bottom: 5%;
}

@media (max-width: 1200px) {
  .electionNavFixed.visible {
    bottom: 1%;
  }
}

@media (max-width: 1024px) {
  .electionNavFixed {
    bottom: 2%;
  }
}

@media (max-width: 768px) {
  .electionNavFixed {
    bottom: 1%;
  }
}

.live-blog-iframe {
  width: 850px;
  height: 800px;
  /* height: 100%; */
  overflow-x: hidden;
  border: 0;
  border-radius: 4px;
}

@media (max-width: 850px) {
  .live-blog-iframe {
    width: 94vw;
  }
}

.membership-ad {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 76px 5%;
}

.membership-ad img {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .membership-ad {
    padding: 45px 5% 35px 5%;
  }
}

.sweepstakes-banner{
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 86px 5% 70px 5%;
}

@media (max-width: 1000px) {
  .sweepstakes-banner {
    padding: 65px 5% 45px 5%;
  }
}


.vote-banners {
  display: none;
  width: 90%;
}

.vote-banners-mobile {
  display: block;
  width: 95%;
}

.air-banner-container {
  display: flex;
  justify-content: center;
  padding: 60px 5%;
  background-color: #ebebeb;
}

@media (min-width: 800px) {
  .vote-banners {
    display: block;
    width: 75%;
  }

  .vote-banners-mobile {
    display: none;
  }
  .air-banner-container {
    padding: 60px 0%;
  }
}

/* black friday gift banner */

.gift-banner {
  position: relative;
  width: 100%;
  background-color: #1d2c37;
}
.gift-banner > img {
  display: block;
  width: 100%; 
  max-width: 100%;
  margin: 0 auto; 
  cursor: pointer;
  aspect-ratio: 650 / 215;
}
.gift-banner > img#mobile {
  display: block;
}
.gift-banner > img#desktop{
  display: none;
}
@media screen and (min-width: 750px) {
  .gift-banner > img {
    max-width: 1400px;
    aspect-ratio: 1400 / 194;
  }
  .gift-banner > img#mobile {
    display: none;
  }
  .gift-banner > img#desktop{
    display: block;
  }
}

/* KEYFRAMES HERE */

.pulse-animation {
  animation: pulse 1400ms linear infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.banner-grow {
  max-height: 0;
  overflow: hidden;
  animation: grow 400ms 500ms cubic-bezier(0,1,0,1) forwards;
}

@keyframes grow {
  from {
    max-height: 0;
    transform: translateY(-150px);
    clip-path: rect(50% 0 0 0);
  }
  to {
    max-height: 150px;
    transform: translateY(0);
    clip-path: unset;
  }
}
