.root {
  display: grid;
}

/*
LayoutA
┌──┬────────┬──┐
│  │   xx   │  │
│  │        │  │
│  │   xx   │  │
│  │        │  │
│  │   xx   │  │
└──┴────────┴──┘
*/

.layoutA {
  max-width: 792px;
  margin: 0 auto;
}

/*
LayoutB
┌─┬─┬┬───────┬─┐
│ │x││  xxx  │ │
│ │ ││       │ │
│ │x││  xxx  │ │
│ │ ││       │ │
│ │x││  xxx  │ │
└─┴─┴┴───────┴─┘
*/

.layoutB {
  grid-gap: 40px;
}

@media (min-width: 1000px) {
  .layoutB {
    grid-template-columns: 1fr 3fr;
    align-items: start;
  }
}

/*
LayoutC
┌─┬───────┬┬─┬─┐
│ │  xxx  ││x│ │
│ │       ││ │ │ 
│ │  xxx  ││x│ │
│ │       ││ │ │
│ │  xxx  ││x│ │
└─┴───────┴┴─┴─┘
*/

.layoutC {
  grid-gap: 40px;
}

@media (min-width: 1000px) {
  .layoutC {
    grid-template-columns: 3fr 1fr;
    align-items: start;
  }
}

/*
LayoutD
┌─┬─┬┬────┬┬─┬─┐
│ │x││ xx ││x│ │
│ │ ││    ││ │ │
│ │x││ xx ││x│ │  
│ │ ││    ││ │ │  
│ │x││ xx ││x│ │  
└─┴─┴┴────┴┴─┴─┘
*/

.layoutD {
  grid-gap: 32px;
}

@media (min-width: 1000px) {
  .layoutD {
    grid-template-columns: 332px 1fr 332px;
    align-items: flex-start;
  }
}

/*
LayoutE
┌─┬────┬┬────┬─┐
│ │ xx ││ xx │ │
│ │    ││    │ │
│ │ xx ││ xx │ │
│ │    ││    │ │
│ │ xx ││ xx │ │
└─┴────┴┴────┴─┘
*/

.layoutE {
  grid-gap: 24px;
}

@media (min-width: 1000px) {
  .layoutE {
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
}

/*
LayoutF
┌──┬─┬┬─┬┬─┬──┐
│  │x││x││x│  │
│  │ ││ ││ │  │
│  │x││x││x│  │  
│  │ ││ ││ │  │  
│  │x││x││x│  │  
└──┴─┴┴─┴┴─┴──┘
*/

.layoutF {
  grid-gap: 32px;
}

@media (min-width: 750px) {
  .layoutF {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1000px) {
  .layoutF {
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
}

/*
LayoutG
┌─┬─┬─┬─┬─┬─┐
│ │x│x│x│x│ │
│ │ │ │ │ │ │
│ │x│x│x│x│ │  
│ │ │ │ │ │ │  
│ │x│x│x│x│ │  
└─┴─┴─┴─┴─┴─┘
*/

.layoutG {
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1000px) {
  .layoutG {
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);
  }
}
