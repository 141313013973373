.bannerContainer {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background-position: left;
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 60px;
}

.cta {
  margin-right: 28px;
  padding: 2px 10px;
  border-radius: 4px;
  flex-shrink: 0;
}

.timer {
  gap: 4px;
  display: none;
  margin-right: 68px;
}
.timer > .timeUnit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
}
.timer > .timeUnit > .time {
  font-size: 48px;
  font-weight: 700;
  height: 70px;
}
.timer > .timeUnit > .unit {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.timerMobile {
  display: flex;
  align-items: center;
  margin-right: 5%;
}
.timerMobile > .unit {
  font-size: 12px;
  font-weight: 700;
}
.timerMobile > .divider {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.timerMobile > svg {
  margin-left: 4px;
}
@media screen and (min-width: 550px) {
  .bannerContainer {
    height: 100px;
  }
  .cta {
    margin-right: 32px;
    padding: 4px 16px;
    border-radius: 6px;
  }
  .timerMobile {
    margin-right: 36px;
  }
}
@media screen and (min-width: 750px) {
  .timer {
    display: flex;
    margin-right: 28px;
  }
  .timer > .timeUnit > .time {
    height: 65px;
  }
  .timerMobile {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .bannerContainer {
    height: 150px;
  }
  .cta {
    margin-right: 60px;
    padding: 8px 24px;
  }
  .timer {
    margin-right: 36px;
  }
  .timer > .timeUnit > .time {
    font-size: 48px;
    height: 68px;
  }
}
@media screen and (min-width: 1400px) {
  .timer {
    margin-right: 68px;
  }
}