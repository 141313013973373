:root {
  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;

  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: white;

  --red: #b33828;
  --white: #ffffff;

  --accents-0: #050505;
  --accents-1: #0d0d0d;
  --accents-2: #141414;
  --accents-3: #1a1a1a;
  --accents-4: #262626;
  --accents-5: #333333;
  --accents-6: #666666;
  --accents-7: #808080;
  --accents-8: #999999;
  --accents-9: #b3b3b3;
  --accents-10: #cccccc;
  --accents-11: #e6e6e6;
  --accents-12: #f2f2f2;

  --font-beaufort: "beaufortproregular", sans-serif;
  --font-beaufort-light: "beaufortproregular", sans-serif;
  --font-beaufort-medium: "beaufort_promedium", sans-serif;
  --font-beaufort-bold: "beaufortprobold", sans-serif;
  --font-beaufort-heavy: "beaufort_proheavy", sans-serif;
  --font-lato: "Lato", sans-serif;

    /* new design system */

    /* backgrounds */
    --neutrals-92: #141414;
    --Background-Base: #0A0909;
    --Background-Container: #151515;
    --Background-Light-Container: #1F1F1F;
    --Decorative-White: #FFF;
    /* font colors */
    --Neutrals-White: #FFF;
    --Interaction-Inverse-Base: #0A0909;
    --Gray---Text: #8F8F8F;
    --neutrals-40: #999;
    --Gray---96: #F5F5F5;
    --Text-Default-Base: #FFF;
    --Icon-Strong: #FFF;
    --Decorative-Almost-Black: #151515;
    --GS-Brand-GS-Black: #241D1D;
    --Text-Default-Subtle: #525252;
    /* spacing */
    --Bases-Spacings-spacing-none: 0;
}
