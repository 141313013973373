.progressBar {
  position: relative;
  height: 4px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
}

.progressBar::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--progress);
  border-radius: 4px;
  background-color: #fff;
}
