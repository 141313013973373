@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fromRightEntry {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.naked {
  all: unset;
}

/* Backgrounds */
.bg-red {
  background-color: var(--red);
}
.bg-white {
  background-color: var(--white);
}

/* Text */
.text-red {
  color: var(--red);
}
.text-white {
  color: var(--white);
}
.text-accents-2 {
  color: var(--accents-2);
}
.text-accents-4 {
  color: var(--accents-4);
}

.text-accents-8 {
  color: var(--accents-8);
}
.text-accents-10 {
  color: var(--accents-10);
}

/* Position  */
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}

/* Display */
.block {
  display: block;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}

/* Animations */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fromRightEntry {
  -webkit-animation-name: fromRightEntry;
  animation-name: fromRightEntry;
}

/* Accessability */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}

/* Visibility */
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/* Height */
.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

/* Width */
.w-full {
  width: 100%;
}

.w-screen {
  width: 100vh;
}

.image-object-fit-contain {
  object-fit: contain;
}

.image-object-fit-cover {
  object-fit: cover;
  object-position: top;
}

.join-to-watch {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 450px;
  padding: 2rem;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
}
.join-to-watch button {
  margin: 1rem 0;
}

.submit-qa {
  max-width: 500px;
  margin: 2rem auto;
  padding: 4rem 2rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
}

.submit-qa h1 {
  font-size: 2rem;
}

.join-to-watch h1 {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .join-to-watch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0;
  }

  .join-to-watch h1 {
    font-size: 1.25rem !important;
  }
}
