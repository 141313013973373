.liveButtonCircle {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #DB3D3B;
}
.liveButtonCircle::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #DB3D3B;
  border-radius: 50%;
  transform: scale(1);
  opacity: .8;
  animation: pulse 2s ease-out infinite;
}
.liveButtonCircle::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #DB3D3B;
  border-radius: 50%;
  transform: scale(1);
  opacity: .8;
  animation: pulse 2s 1s ease-out infinite;
}
@keyframes pulse {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}