.btn {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 6px;
  font-family: 'Libre Franklin';
  text-align: center;
  cursor: pointer;
  appearance: button;
}

.btn:disabled {
  border-color: #b9bdc6;
  background-color: #b9bdc6;
  background-image: none;
  color: #ffffff;
  cursor: not-allowed;
}

.sm {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 1.3;
}

.lg {
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1;
}

.primary {
  border: 1px solid var(--red);
  background-color: var(--red);
  color: var(--white);
}

.secondary {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--text-secondary);
}

.minimal {
  background-color: transparent;
  color: var(--text-base);
}

.minimal.sm,
.minimal.lg {
  padding-right: 0;
  padding-left: 0;
}
