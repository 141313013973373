.pageHeading {
  margin-bottom: 1rem;
  font-family: "Libre Franklin";
  font-size: 32px;
  line-height: 115%;
}

.sectionHeading {
  margin-bottom: 1rem;
  font-family: "Libre Franklin";
  font-size: 1.5rem;
  line-height: 115%;
}

.heading {
  margin-bottom: 1rem;
  font-family: 'Libre Franklin';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 125%;
}

.subHeading {
  margin-bottom: 0.5rem;
  font-family: 'Libre Franklin';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 125%;
}

.body {
  margin: 1rem 0;
  font-family: 'Libre Franklin';
  font-size: 0.875rem;

  font-style: normal;
  font-weight: normal;
  line-height: 162.5%;
}

.bodySmall {
  margin: 1rem 0;
  font-family: 'Libre Franklin';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
}

.description {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'Libre Franklin';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
}

.kicker {
  margin-bottom: 0.25rem;
  font-family: "Libre Franklin";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 115%;
  text-transform: uppercase;
}

@media (min-width: 1000px) {
  .pageHeading {
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  .body {
    font-size: 1rem;
  }

  .sectionHeading {
    font-size: 2rem;
  }
}

/* Perposly kept at the bottom to cancel out any overlapping properties above */

.heading + .body {
  margin-top: 0;
}

.body:last-child {
  margin-bottom: 0;
}

.noSpacing {
  margin: 0;
}
