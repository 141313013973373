.container {
  box-sizing: content-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5%;
  padding-left: 5%;
}

.sm {
  max-width: 588px;
}

.md {
  max-width: 792px;
}

.lg {
  max-width: 996px;
}

.xl {
  max-width: 1200px;
}

.xxl {
  max-width: 1400px;
}

.tight {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.generous {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 1000px) {
  .tight {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .generous {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
