@keyframes onMount {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    left: -350px;
  }
  100% {
    left: 0;
  }
}

.jbp-carousel-container {
  padding: 10px 0px 60px 0px;
}

.jbp-carousel-title {
  display: flex;
  align-items: center;
  max-width: 84%;
  color: #f1f1f1;
  font-family: 'Libre Franklin';
  font-size: 26px;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .jbp-carousel-container {
    padding: 10px 0px 60px 0px;
  }
  .jbp-carousel-title {
    display: flex;
    align-items: center;
    max-width: 84%;
    padding-left: 16px;
    color: #f1f1f1;
    font-family: 'Libre Franklin';
    font-size: 26px;
    line-height: 1.2;
  }
}

@media (max-width: 1010px) {
  .jbp-carousel-container {
    padding: 10px 0px 40px 0px;
  }
  .jbp-carousel-title {
    display: flex;
    align-items: center;
    max-width: 84%;
    padding-left: 20px;
    color: #f1f1f1;
    font-family: 'Libre Franklin';
    font-size: 26px;
    line-height: 1.2;
  }
}

@media (max-width: 700px) {
  .jbp-carousel-container {
    padding: 10px 16px 20px 8px;
  }

  .jbp-carousel-image-wrapper {
    margin-left: -4rem;
  }

  .jbp-carousel-title {
    display: flex;
    align-items: center;
    max-width: 84%;
    color: #f1f1f1;
    font-family: 'Libre Franklin';
    font-size: 22px;
    line-height: 1.2;
  }
}

.version2-layout .menu-container {
  border-bottom: 0.5px solid #666;
  background-color: #272727;
  font-family: 'Libre Franklin';
}

.version2-layout .menu-container .logo {
  display: flex;
  align-items: center;
}
.version2-layout .menu-container .logo .flag-logo {
  opacity: 0.5;
}
.version2-layout .menu-container .menu-button-link a {
  padding: 10px 24px 8px 24px;
  border: 1px solid #b33828;
  background-color: #b33828;
  font-family: 'Libre Franklin';
}
.version2-layout .version2-headerLinks > .active {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.version2-layout .version2-headerLinks {
  flex-shrink: 0;
}
/* Election Nav */
.version2-layout-election .menu-container-election {
  background-color: #fff;
  font-family: 'Libre Franklin';
}
.version2-layout-election .menu-container-election .menu-button-link-election a {
  padding: 10px 24px 8px 24px;
  border: 1px solid #b33828;
  background-color: #b33828;
  font-family: 'Libre Franklin';
}
.version2-layout-election .version2-headerLinks-election > .active {
  color: black;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

/* 
 * START - All Page Banner
 */
@keyframes jiggle {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(10deg);
  }
  2% {
    transform: rotate(-10deg);
  }
  3% {
    transform: rotate(10deg);
  }
  4% {
    transform: rotate(-10deg);
  }
  5% {
    transform: rotate(10deg);
  }
  6% {
    transform: rotate(-10deg);
  }
  7% {
    transform: rotate(10deg);
  }
  8% {
    transform: rotate(-10deg);
  }
  9% {
    transform: rotate(10deg);
  }
  10% {
    transform: rotate(0);
  }
}

.digital-number {
  display: inline-block;
  margin: 0 2px;
}

.digital-number .dn-row {
  display: flex;
  margin: 1px 0;
}

.digital-number .dn-row .dn-col {
  width: 3px;
  height: 3px;
  margin: 0 0.5px;
}

.digital-number .dn-row .dn-col.active {
  background-color: #d90000;
}

.digital-number.sm .dn-row {
  margin: 0.5px 0;
}

.digital-number.sm .dn-row .dn-col {
  width: 2px;
  height: 2px;
  margin: 0 0.25px;
}

.all-page-banner {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 80px;
  animation: onMount 500ms ease-out forwards;
  background-color: #140d0b;
}

.all-page-banner.ap-v3 {
  height: 100px;
  cursor: pointer;
}

.all-page-banner.ap-v4 {
  height: 100px;
  cursor: pointer;
}
.all-page-banner.ap-v5 {
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  background-color: #161614;
  background-image: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasilTH4_550_x_60.jpg');
  background-repeat: no-repeat !important;
  background-position: left;
  background-size: auto 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  gap: 4px;
  cursor: pointer;
}

.all-page-banner.ap-v5.isToday {
  background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_250x100_streaming.png');
}
.all-page-banner.ap-v5.isTomorrow {
  background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_250x100_tomorrow.png');
}
.all-page-banner.ap-v5.isFuture {
  background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/CBASIL_AIR_250x100.png');
}

.all-page-banner.ap-v3 .smoke {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/images/ap-banner/smoke.png);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100%;
}

.all-page-banner.ap-v3 .show-desktop {
  display: none;
}

.with-timer {
  height: 84px;
}

.all-page-banner .banner-left {
  display: flex;
  padding: 16px 0;
}

.with-timer .banner-left {
  display: flex;
  padding: 15px 0;
}

.ap-v4.with-timer .banner-left {
  padding: 0;
}
.ap-v5.with-timer .banner-left {
  padding: 0;
}

.with-timer.ap-v3 .banner-left {
  display: flex;
  align-items: center;
  padding: 0;
}

.all-page-banner .gift {
  display: none;
  position: relative;
  margin-right: 30px;
}

.all-page-banner .gift .bg-pattern {
  clip-path: polygon(0 0, 65% 0, 100% 100%, 0 100%);
  width: 10vw;
  height: 100px;
  background-image: url(/images/ap-banner/plus-bg.png);
}

.all-page-banner .gift img {
  position: absolute;
  top: 0;
  right: -18px;
}

.all-page-banner .graphic {
  padding: 0 20px;
}

.with-timer .graphic {
  padding: 3px 10px 0;
}

.ap-v3 .graphic-backstage {
  width: 115px;
  height: 100%;
  margin: 0 -4px;
  background-image: url(/images/ap-banner/backstage-presidential-debate.png);
  background-repeat: no-repeat;
  background-position: center 48%;
  background-size: 100%;
}

.ap-v4 .graphic-judged {
  width: 132px;
  height: 100%;
  margin: 0;
  background-image: url(/images/ap-banner/judged-mobile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.ap-v5 .graphic-birchum {
  width: 140px;
  height: 100%;
  margin: 0;
  background-image: url(/images/ap-banner/birchum_banner_characters_only.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.ap-v4.with-timer .text-content {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 38px;
  padding-left: 9px;
  font-family: 'Libre Franklin';
  text-transform: uppercase;
}

.ap-v4.with-timer .text-content .text-normal {
  font-family: 'Libre Franklin';
  font-weight: 100;
}

.ap-v4.with-timer .text-content h2 {
  font-size: 12px;
}

.ap-v4.with-timer .text-content h2 .title-desktop {
  display: none;
}

.ap-v4.with-timer .text-content h2 .title-mobile {
  display: inline;
}

.ap-v4 .logo {
  position: absolute;
  top: 22%;
  left: 58px;
  width: 74px;
}
.ap-v5.with-timer .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: sans-serif;
  text-transform: uppercase;
}

.ap-v5.with-timer .text-content .text-normal {
  font-family: sans-serif;
  font-weight: 100;
}

.ap-v5.with-timer .text-content h2 {
  font-size: 12px;
}

.ap-v5.with-timer .text-content h2 .title-desktop {
  display: none;
}

.ap-v5.with-timer .text-content h2 .title-mobile {
  display: inline;
}

.ap-v5 .logo {
  position: absolute;
  top: 55%;
  left: 20px;
  width: 108px;
}

.all-page-banner .graphic img {
  height: 48px;
  animation: jiggle 15s ease-in-out infinite;
}

.all-page-banner .text-content {
  padding: 0;
  color: #000000;
}

.all-page-banner .text-content h2 {
  margin-bottom: 6px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner .text-content p {
  margin-bottom: 6px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.with-timer .text-content p {
  white-space: nowrap;
}

.all-page-banner.ap-v3 .text-content h1 {
  margin-bottom: 4px;
  color: #757575;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 8px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.all-page-banner.ap-v3 .text-content h2 {
  margin-bottom: 6px;
  color: #fff;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner .text-content .timer-mobile {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner.ap-v4 .text-content .timer-mobile {
  display: flex;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}
.all-page-banner.ap-v5 .text-content .timer-mobile {
  display: flex;
  color: #ffffff;
  font-family: 'NeueHaasGroteskText75Bold', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner .banner-right {
  display: flex;
  width: 32px;
}

.all-page-banner.with-timer.ap-v3 .banner-right {
  display: flex;
  width: 36px;
  padding: 0;
  background-image: url(/images/ap-banner/whiskey.png);
  background-repeat: no-repeat;
  background-position: 10vw 6%;
  background-size: 200px;
}

.all-page-banner.with-timer.ap-v4 .banner-right {
  align-items: center;
  justify-content: center;
  width: unset;
  padding: 0 8px 0 0;
}
.all-page-banner.with-timer.ap-v5 .banner-right {
  align-items: center;
  justify-content: center;
  width: unset;
  padding: 0 5% 0 0;
}

.all-page-banner .timer {
  display: none;
  margin-right: 8px;
  gap: 4px;
  color: #000;
}
.time-over {
  color: #fff;
}
.all-page-banner.ap-v3 .timer {
  display: none;
  align-items: center;
}

.all-page-banner .timer .time-unit {
  width: 50px;
  height: 64px;
  padding-top: 6px;
  border-radius: 4px;
  text-align: center;
}

.all-page-banner.ap-v5 .timer .time-unit {
  width: 80px;
}

.all-page-banner.ap-v3 .timer .time-unit {
  width: 68px;
  height: 68px;
  padding-top: 8px;
  border-radius: 4px;
  background-color: #202020;
  text-align: center;
}

.all-page-banner .timer .time-unit .time {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner.ap-v3 .timer .time-unit .time {
  padding-bottom: 1px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
}

.all-page-banner .timer .time-unit .unit {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.all-page-banner.ap-v3 .timer .time-unit .unit {
  color: #000000;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 14px;
  line-height: normal;
}

.all-page-banner.ap-v4 .timer .time-unit {
  height: unset;
}

.all-page-banner.ap-v4 .timer .time-unit .time {
  margin-bottom: 2px;
  font-size: 24px;
}
.all-page-banner.ap-v5 .timer .time-unit {
  height: unset;
}

.all-page-banner.ap-v5 .timer .time-unit .time {
  color: #F6222B;
  font-size: 48px;
}

.all-page-banner .timer .time-unit .unit {
  margin-bottom: 8px;
  color: #C6C9CC;
}

.all-page-banner .timer .seconds {
  display: block;
}

.all-page-banner .mobile-cta-link a {
  display: flex;
  align-items: center;
  width: 32px;
  height: 80px;
}

.with-timer .mobile-cta-link a {
  justify-content: center;
}
.mobile-cta-link > svg * {
  stroke: #fff;
}
.all-page-banner .cta-link {
  display: none;
  width: 190px;
  margin: 0 auto 6px;
  padding: 12px;
  border-radius: 6px;
  background-color: #fff;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.all-page-banner .terms {
  display: none;
  color: #dadada;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.all-page-banner .terms.terms-mobile {
  display: block;
  font-size: 8px;
  white-space: nowrap;
}

.all-page-banner.ap-v2 {
  position: relative;
  z-index: 1;
}

.all-page-banner.ap-v2 .graphic {
  position: relative;
  width: 33vw;
}

.all-page-banner.ap-v2 .graphic img {
  position: absolute;
  top: 7px;
  left: 50%;
  width: 85%;
  max-width: 350px;
  height: auto;
  transform: translateX(-50%);
  animation: none;
}

.all-page-banner.ap-v2 .timer .time-unit {
  width: 56px;
  border: 1px solid #fff;
  background-color: unset;
}

.all-page-banner.ap-v2 .timer .time-unit .time {
  color: #d90000;
  font-family: 'Libre Franklin';
}

.all-page-banner.ap-v2 .timer-mobile,
.all-page-banner.ap-v3 .timer-mobile {
  display: flex;
}

.all-page-banner.ap-v2 .timer-mobile .unit {
  margin: 0 5px;
}

.all-page-banner.ap-v3 .timer-mobile .divider {
  margin: 0 2px;
}

.all-page-banner.ap-v2 .cta-link {
  margin-top: 7px;
}

.all-page-banner.ap-v2 .cta-link svg {
  margin-right: 4px;
}

.ap-v3 .text-content-right {
  display: flex;
  align-items: center;
}

.all-page-banner.ap-v4 .timer .time.time-over {
  font-size: 20px;
}
.all-page-banner.ap-v5 .timer .time.time-over {
  color: #ffffff;
  font-size: 20px;
}
.all-page-banner.ap-v5 .mobile-cta-link {
  display: flex;
}

@media screen and (min-width: 360px) {
  .with-timer .mobile-cta-link a {
    justify-content: flex-start;
  }

  .ap-v3 .graphic-backstage {
    margin: 0 18px;
  }

  .all-page-banner.with-timer.ap-v3 .banner-right {
    width: 46px;
  }
}

@media screen and (min-width: 400px) {
  .with-timer .graphic {
    padding: 3px 20px 0;
  }

  .ap-v4.with-timer .text-content {
    padding-bottom: 26px;
    padding-left: 63px;
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 15px;
  }

  .ap-v4 .logo {
    position: absolute;
    top: 22%;
    left: 70px;
    width: 108px;
  }

  .ap-v5.with-timer .text-content h2 {
    font-size: 15px;
  }

  .ap-v5 .logo {
    position: absolute;
    top: 55%;
    left: 20px;
    width: 108px;
  }
}

@media screen and (min-width: 768px) {
  .all-page-banner {
    height: 100px;
  }

  .all-page-banner .banner-left {
    padding: 0px;
  }

  .all-page-banner:not(.with-timer) .gift {
    display: block;
  }

  .all-page-banner .graphic {
    padding: 16px 20px 16px 0;
  }

  .with-timer .graphic {
    padding: 16px;
  }

  .ap-v3 .graphic-backstage {
    width: 200px;
    margin: 0;
  }

  .all-page-banner .graphic img {
    height: 68px;
  }

  .all-page-banner .text-content {
    padding: 24px 0;
  }

  .with-timer .text-content {
    padding: 30px 0;
  }

  .all-page-banner.ap-v3 .text-content h1 {
    font-size: 14px;
  }

  .all-page-banner .text-content h2 {
    margin-bottom: 4px;
    font-size: 22px;
  }

  .with-timer .text-content h2 {
    font-size: 18px;
  }

  .all-page-banner.ap-v3 .text-content h2 {
    font-size: 22px;
  }

  .all-page-banner .text-content p {
    font-size: 16px;
  }

  .with-timer .text-content p {
    font-size: 14px;
  }

  .all-page-banner.ap-v3 .timer {
    display: flex;
  }

  .all-page-banner .text-content .timer-mobile {
    display: none;
  }

  .all-page-banner .banner-right {
    width: unset;
    padding-top: 18px;
    padding-right: 5vw;
  }

  .all-page-banner.with-timer .banner-right {
    width: 32px;
    padding-right: 1.5vw;
  }

  .all-page-banner.with-timer.ap-v3 .banner-right {
    width: unset;
    /* padding-right: 12vw; */
  }

  .all-page-banner .cta-link {
    display: block;
  }

  .all-page-banner .terms {
    display: block;
  }

  .all-page-banner .mobile-cta-link {
    display: none;
  }

  .all-page-banner .terms.terms-mobile {
    display: none;
  }

  .ap-v4 .graphic-judged {
    width: 292px;
    height: 100%;
    margin: 0 -4px;
    background-image: url(/images/ap-banner/judged-desktop.png);
  }
  .ap-v5 .graphic-birchum {
    width: 140px;
    height: 100%;
    margin: 0 -4px;
    background-image: url(/images/ap-banner/birchum_banner_characters_only.png);
  }
  .all-page-banner.ap-v4 .text-content .timer-mobile {
    display: none;
  }

  .ap-v4.with-timer .text-content {
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 18px;
  }

  .ap-v4 .logo {
    position: absolute;
    top: 50%;
    left: 176px;
    width: 108px;
    transform: translateY(-50%);
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 21px;
  }

  .ap-v4.with-timer .text-content h2 .title-desktop {
    display: inline;
  }

  .ap-v4.with-timer .text-content h2 .title-mobile {
    display: none;
  }

  .ap-v5.with-timer .text-content {
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 18px;
  }

  .ap-v5.with-timer .text-content h2 {
    font-size: 21px;
  }

  .ap-v5.with-timer .text-content h2 .title-desktop {
    display: inline;
  }

  .ap-v5.with-timer .text-content h2 .title-mobile {
    display: none;
  }
  .all-page-banner.ap-v5 .timer .time.time-over {
    padding: 8px 24px 8px 24px;
    border-radius: 6px;
    background-color: #F6222B;
    color: white;
    font-size: 16px !important;
    font-weight: 600;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 825px) {
  .all-page-banner.ap-v5 {
    height: 100px;
    background-image: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasilTH4_1000_x_100.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: auto 100%;
    cursor: pointer;
  }
  .all-page-banner.ap-v5.isToday {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_1200x150_streaming.png');
  }
  .all-page-banner.ap-v5.isTomorrow {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_1200x150_tomorrow.png');
  }
  .all-page-banner.ap-v5.isFuture {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/CBASIL_AIR_1200x150.png');
  }
  .all-page-banner .timer {
    display: flex;
  }
  .all-page-banner.ap-v5 .text-content .timer-mobile {
    display: none;
  }
  .all-page-banner.ap-v5 .mobile-cta-link {
    display: none;
  }
  .all-page-banner.with-timer.ap-v5 .banner-right {
    padding: 0 20px 0 0;
  }
}

@media screen and (min-width: 900px) {
  .ap-v3 .graphic-backstage {
    width: 200px;
  }
  .all-page-banner .gift {
    margin-right: 50px;
  }

  .with-timer .gift {
    display: block;
    margin-right: 24px;
  }

  .all-page-banner .graphic {
    padding-right: 32px;
  }

  .with-timer .graphic {
    padding: 16px 16px 16px 0;
  }

  .all-page-banner .text-content {
    padding: 20px 0;
  }

  .with-timer .text-content {
    padding: 28px 0;
  }

  .all-page-banner .text-content h2 {
    font-size: 28px;
  }

  .all-page-banner.ap-v3 .text-content h2 {
    font-size: 24px;
  }

  .with-timer .text-content h2 {
    font-size: 18px;
  }

  .with-timer .text-content p {
    font-size: 14px;
  }

  .all-page-banner .banner-right {
    padding-right: 10vw;
  }

  .all-page-banner.with-timer.ap-v3 .banner-right {
    /* padding-right: 10vw; */
    background-position: 9vw 7%;
    background-size: 220px;
  }

  .all-page-banner .cta-link {
    width: 232px;
  }

  .all-page-banner.ap-v3 .timer .time-unit {
    width: 80px;
    height: 70px;
  }

  .all-page-banner.ap-v3 .timer .time-unit .time {
    padding-bottom: 1px;
    font-size: 28px;
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 30px;
  }

  .all-page-banner.ap-v4 .timer .time.time-over {
    font-size: 24px;
  }
  .ap-v5.with-timer .text-content h2 {
    font-size: 30px;
  }

  .all-page-banner.ap-v5 .timer .time.time-over {
    font-size: 24px;
  }
}

@media screen and (min-width: 960px) {
  .all-page-banner.ap-v3 .show-desktop {
    display: inline;
  }
}

@media screen and (min-width: 1200px) {
  .all-page-banner.ap-v5 {
    height: 150px;
    background-image: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasilTH4_1440_x_150.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: auto 100%;
    cursor: pointer;
  }
  .all-page-banner.ap-v3 {
    height: 150px;
  }

  .ap-v3 .graphic-backstage {
    width: 250px;
  }

  .with-timer .gift {
    margin-right: 30px;
  }

  .all-page-banner .gift .bg-pattern {
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
    width: 18vw;
    max-width: 400px;
  }

  .all-page-banner.with-timer .gift .bg-pattern {
    width: 16vw;
  }

  .with-timer .graphic {
    padding-right: 18px;
  }

  .all-page-banner .banner-right {
    padding-right: 15vw;
  }

  .all-page-banner.with-timer.ap-v3 .banner-right {
    padding-right: 1.5vw;
    background-position: 8vw 20%;
    background-size: 380px;
  }

  .with-timer .text-content {
    padding: 24px 0;
  }

  .with-timer .text-content h2 {
    font-size: 24px;
  }

  .all-page-banner.ap-v3 .text-content h2 {
    font-size: 30px;
  }

  .with-timer .text-content p {
    font-size: 16px;
  }

  .all-page-banner .timer {
    margin-right: 16px;
  }

  .all-page-banner.ap-v2 .timer .time-unit,
  .all-page-banner .timer .time-unit {
    width: 80px;
  }
  .all-page-banner.ap-v5 .timer .time-unit {
    width: 80px;
  }

  .all-page-banner .timer .seconds {
    display: block;
  }

  .all-page-banner.ap-v3 .timer .time-unit {
    width: 105px;
    height: 85px;
  }

  .all-page-banner.ap-v3 .timer .time-unit .time {
    font-size: 40px;
  }

  .all-page-banner.ap-v4 {
    height: 150px;
  }

  .ap-v4 .graphic-judged {
    width: 440px;
  }

  .all-page-banner.ap-v4 .timer .time-unit .time {
    margin-bottom: 6px;
    font-size: 32px;
  }
  .ap-v5 .graphic-birchum {
    width: 290px;
    background-size: contain;
  }

  .all-page-banner.ap-v5 .timer .time-unit .time {
    margin-bottom: 0;
    font-size: 48px;
  }

  .all-page-banner .timer .time-unit .unit {
    margin-bottom: 12px;
  }

  .ap-v4 .logo {
    left: 234px;
    width: 160px;
  }

  .ap-v4.with-timer .text-content {
    padding-left: 0;
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 36px;
  }

  .all-page-banner.ap-v4 .timer .time.time-over {
    font-size: 28px;
  }
  .ap-v5 .logo {
    left: 200px;
    width: 160px;
    transform: translateY(-50%);
  }

  .ap-v5.with-timer .text-content {
    padding-left: 92px;
  }

  .ap-v5.with-timer .text-content h2 {
    font-size: 30px;
  }

  .all-page-banner.ap-v5 .timer .time.time-over {
    font-size: 28px;
  }
}

@media screen and (min-width: 1300px) {
  .all-page-banner.ap-v5.isToday {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_1440x150_streaming.png');
  }
  .all-page-banner.ap-v5.isTomorrow {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/cbasil_air_1440x150_tomorrow.png');
  }
  .all-page-banner.ap-v5.isFuture {
    background: url('https://dailywireplus-v2.imgix.net/images/dailywire.com/CBASIL_AIR_1440x150.png');
  }
}

@media screen and (min-width: 1400px) {
  .with-timer .gift {
    margin-right: 50px;
  }

  .with-timer .graphic {
    padding-right: 32px;
  }

  .all-page-banner.with-timer .banner-right {
    padding-right: 5vw;
  }

  .all-page-banner.with-timer.ap-v3 .banner-right {
    padding-right: 8vw;
    background-position: 165% 26%;
  }

  .with-timer .text-content h2 {
    font-size: 28px;
  }

  .all-page-banner.ap-v3 .text-content h2 {
    font-size: 38px;
  }

  .with-timer .text-content p {
    font-size: 18px;
  }

  .all-page-banner .timer {
    margin-right: 32px;
  }

  .ap-v4 .logo {
    left: 320px;
  }
  .ap-v5 .logo {
    left: 200px;
  }

  .all-page-banner.ap-v4 .timer {
    margin-right: 48px;
  }

  .all-page-banner.ap-v4 .timer .time-unit .time {
    font-size: 36px;
  }

  .ap-v4.with-timer .text-content {
    padding-left: 92px;
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 42px;
  }
  .all-page-banner.ap-v5 .timer {
    margin-right: 48px;
  }

  .all-page-banner.ap-v5 .timer .time-unit .time {
    font-size: 48px;
  }

  .ap-v5.with-timer .text-content {
    padding-left: 92px;
  }

  .ap-v5.with-timer .text-content h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1500px) {
  .all-page-banner.ap-v3 .text-content h2 {
    font-size: 46px;
  }

  .ap-v4.with-timer .text-content h2 {
    font-size: 50px;
  }
  .ap-v5.with-timer .text-content h2 {
    font-size: 36px;
  }
}

/* 
 * END - All Page Banner
 */

.Watch__Banner {
  box-sizing: content-box;
  max-width: 1161.5px;
  margin: 0 auto 32px;
  overflow: hidden;
}

.Watch__Banner img {
  border: 0.5px solid rgba(11, 11, 17, 0.11) !important;
  border-radius: 15px !important;
}

.Watch__Callout {
  box-sizing: content-box;
  max-width: 1161.5px;
  margin: 16px auto 32px;
}

.Watch__Callout-container {
  display: flex;
  border-radius: 15px;
  background: url(/images/callout/callout-background-2.svg);
  background-size: cover;
  font-family: 'Libre Franklin';
}

.Watch__Callout-cta {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: url(/images/callout/callout-background-3.svg);
  background-size: cover;
}

.Watch__Callout-container-browse,
.Watch__Callout-container-browse .Watch__Callout-cta {
  background: none;
  background-color: #262626;
}

.Watch__Callout-thumb,
.Watch__Callout-cta {
  position: relative;
  width: 25%;
  min-width: 240px;
}

.Watch__Callout-thumb {
  position: relative;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  aspect-ratio: 16/9;
}
.Watch__Callout-thumb a {
  display: inline-table;
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  padding: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.Watch__Callout-thumb span {
  display: inline-table;
  position: absolute;
  right: 10px;
  bottom: 9px;
  width: 16px;
  height: 16px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.65);
  border-radius: 50%;
}

.Watch__Callout-thumb svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  fill: rgba(255, 255, 255, 0.65);
}

.Watch__Callout-content {
  flex-grow: 1;
  padding: 24px 32px;
  color: #fff;
}

.Watch__Callout-content h5 {
  position: relative;
  font-size: 19px;
}

.Watch__Callout-content p {
  margin-top: 4px;
  font-size: 15px;
  line-height: 1.3;
}

.Watch__Callout-cta a {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 4px 36px;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}

@media screen and (max-width: 838px) {
  .Watch__Callout-container {
    flex-wrap: wrap;
    background-image: url(/images/callout/callout-background-mobile.svg);
  }

  .Watch__Callout-container-browse.Watch__Callout-container {
    flex-wrap: wrap;
    background-image: none;
  }

  .Watch__Callout-thumb {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
  }

  .Watch__Callout-content {
    flex-grow: unset;
    width: 100%;
    padding: 14px;
  }

  .Watch__Callout-cta {
    width: 100%;
    height: 80px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .Watch__Callout-cta a {
    padding: 4px 64px;
  }
}

.Watch__Callout-overlay {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.74)),
    linear-gradient(rgba(29, 0, 82, 0.016), rgba(29, 0, 82, 0.016)),
    linear-gradient(rgba(126, 126, 126, 0.536), rgba(126, 126, 126, 0.5)),
    linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
}

.Watch__Callout-overlay__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-height: 75%;
  transform: translate(-50%, -50%);
}

.Watch__Callout-overlay__close {
  text-align: right;
}

.Watch__Callout-overlay__close a {
  display: inline-table;
  position: relative;
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
  padding: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.Watch__Callout-overlay__close svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  fill: rgba(255, 255, 255, 0.3);
}

.Watch__Callout-overlay__video {
  padding: 16px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.25);
}

.Watch__Callout-overlay__video video {
  display: table;
}

@media screen and (max-width: 838px) {
  .Watch__Callout-overlay__content {
    width: 85%;
  }

  .Watch__Callout-overlay__video {
    padding: 12px;
  }
}

@media screen and (max-height: 500px) {
  .Watch__Callout-overlay__video {
    height: 65vh;
  }
}

.Watch__Browse {
  background: url(/images/section_background/6_20.svg), url(/images/section_background/5_84.svg),
    url(/images/section_background/4_30.svg), url(/images/section_background/3_84.svg),
    url(/images/section_background/2_94.svg), url(/images/section_background/1_100.svg);
}

.Watch__Shows {
  width: 100%;
  max-width: calc(1161.5px + 32px);
  margin: auto;
  padding: 0px 16px 20px;
  animation: onMount 0.3s ease-in-out;
}
.Watch__Shows:last-of-type {
  border-bottom: 0px none;
}
.Watch__Shows__Top {
  margin-bottom: 60px;
  color: #fff;
  text-align: center;
}
.Watch__Shows__Top--Heading {
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: 550;
  line-height: 120%;
}
.Watch__Shows__Top--SubHeading {
  max-width: 750px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 450;
  line-height: 1.4;
}
.Watch__Shows__List {
  display: flex;
  grid-gap: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.Watch__Shows__List--Item {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}
.Watch__Shows__List--Item--ImageWrapper {
  position: relative;
  width: fit-content;
}
.Watch__Shows__List--Item--ImageWrapper--Tag {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 1px;
  max-width: 100%;
  padding: 6px 14px;
  overflow: hidden;
  border-radius: 0 6px 6px 0;
  background-color: #db3d3b;
  color: white;
  font-size: 13px;
}
.Watch__Shows__List--Item--ImageWrapper--Tag-small {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 0.5px;
  max-width: 100%;
  padding: 6px 14px;
  overflow: hidden;
  border-radius: 0 6px 6px 0;
  background-color: #db3d3b;
  color: white;
  font-size: 13px;
}

.Watch__Shows__List--Item--ImageWrapper--Tag-land {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
  max-width: 100%;
  padding: 6px 14px;
  overflow: hidden;
  border-radius: 0 6px 6px 0;
  background-color: #db3d3b;
  color: white;
  font-size: 13px;
}

.Watch__Shows__List--Item img {
  border: 0.5px solid rgba(11, 11, 17, 0.11) !important;
  border-radius: 15px;
}
.Watch__Shows__List--Item--Name {
  max-width: 215px;
  margin: 0 auto;
  color: #949494;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.Watch__Shows__List--Item:hover {
  cursor: pointer;
}
.Watch__Shows__List--Item:hover .Watch__Shows__List--Item--Placeholder {
  opacity: 1;
}
.Watch__Shows__Announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.Watch__Shows__Announcement--Label {
  margin-top: 12px;
  font-size: 12px;
}
.Watch__Movies__Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  color: #fff;
}
.Watch__Categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 11vw 250px;
}
.Watch__Categories--Heading {
  margin-bottom: 62px;
  padding: 15px 50px;
  border-bottom: 1px solid #b5b5b5;
  color: #fff;
}
.Watch__Categories__Pills {
  display: flex;
  grid-gap: 26px 48px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.Watch__CategoryPill {
  padding: 8px 32px;
  border-radius: 32px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 450;
  text-align: center;
  cursor: pointer;
}
.Watch__Channels {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  animation: onMount 0.3s ease-in-out;
}
.Watch__Channels__Logo {
  margin-top: 60px;
}
.Watch__Channels__Active .Watch__Channels__List--Item--Placeholder {
  opacity: 1 !important;
}
.Watch__Channels__List {
  display: flex;
  grid-gap: 56px 16px;
  align-items: center;
  justify-content: center;
  margin: 150px auto;
}
.Watch__Channels__List--Item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.Watch__Channels__List--Item img {
  border: 0.5px solid rgba(11, 11, 17, 0.11) !important;
}
.Watch__Channels__List--Item:hover .Watch__Channels__List--Item--Placeholder {
  opacity: 1;
}
.Watch__Channels__List--Item--Placeholder {
  position: absolute;
  top: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  transition: opacity 0.3s ease-in-out;
  border: 1px solid #2869b3;
  border-radius: 3px;
  opacity: 0;
  background-color: rgba(40, 105, 179, 0.1);
}
.Watch__Channels__List--Item--Name {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.Watch__Channels__LogoLine {
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
}
a.button-start-browsing {
  width: 190px;
  height: 40px;
  margin-bottom: 24px;
  padding: 9px 16px 0 16px;
  border: 0px none;
  border-radius: 40px;
  background-color: #fff;
  color: #2b2b2b;
  font-family: 'Libre Franklin';
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  user-select: none;
}

.Watch__Movies {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-bottom: 40px;
  animation: onMount 0.3s ease-in-out;
  gap: 14px;
  justify-items: center;
}

.version2-watch-video-container {
  max-width: 1161.5px;
  margin: 0 auto;
}
.version2-watchsection-container {
  padding: 0;
}
.version2-watchsection-itemType-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.version2-watchsection-itemType-title {
  display: flex;
  align-items: center;
  max-width: 84%;
  padding-left: 12px;
  color: #f1f1f1;
  font-family: 'Libre Franklin';
  font-size: 26px;
  line-height: 1.2;
}
.version2-watchsection-itemType-live {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin-left: 24px;
  padding: 0 6px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #f1f1f1;
  font-family: 'Libre Franklin';
  font-size: 12px;
}

.version2-watchsection-itemType-live > span {
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background: #b33828;
  font-weight: bold;
}
.version2-watchsection-itemType-viewAll {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  color: #2f2d30;
  font-family: 'Libre Franklin';
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.show-title-line {
  position: relative;
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
}

.post-title-line {
  position: relative;
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#808080), to(rgba(0, 0, 0, 0)));
  background-color: transparent;
  background-image: -webkit-linear-gradient(left, #808080, rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, #808080, rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(left, #808080, rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, #808080, rgba(0, 0, 0, 0));
}
.version2-watchsection-itemType-list .grid {
  position: relative;
  left: -11px;
  width: calc(100% + 11px);
  margin: 20px 0;
}
.version2-watchsection-itemType-list .Carousel_lens__F14aa {
  padding: 0 8px;
}
.version2-watchsection-item-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 320px;
  height: 240px;
  padding: 0 10px;
  color: #fff;
  cursor: pointer;
}

.version2-watchsection-item-img {
  padding: 0px 4px;
  border: 0.5px solid rgba(11, 11, 17, 0.11) !important;
  border-radius: 15px;
}
.version2-watchsection-item-tag-container {
  display: flex;
  position: absolute;
  top: 14px;
  left: 24px;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 25px;
  overflow: hidden;
  border-radius: 6px;
  background: #000000;
  color: #fff;
  font-family: 'Libre Franklin';
  font-size: 12px;
}

.version2-watchsection-item-title {
  display: -webkit-box;
  margin-top: 9px;
  overflow: hidden;
  color: #949494;
  font-family: 'Libre Franklin';
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.version2-watchsection-item-title-portrait {
  display: -webkit-box;
  margin-top: 9px;
  color: #949494;
  font-family: 'Libre Franklin';
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  text-overflow: wrap;
  -webkit-box-orient: vertical;
}


.version2-watchsection-item-subtitle {
  display: -webkit-box;
  margin-top: 2px;
  overflow: hidden;
  color: #949494;
  font-family: 'Libre Franklin';
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  text-overflow: ellipsis;
}

.version2-videoListNav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 1rem 0 1rem;
  border-bottom: 0.5px solid #666;
  background-image: linear-gradient(to right, #1a1a1a, #272727, #272727, #272727);
  color: #fff;
}
.version2-videoListNav__List--Item {
  margin-right: 3rem;
  padding-bottom: 8px;
  cursor: pointer;
}
.version2-videoListNav__List--Item:last-child {
  margin-right: 0;
}
.version2-videoListNav__List--Item.version2-videoListNav__Active {
  border-bottom: 1px solid #fff;
}

.Watch__Shows__Kids .show__announcement__video {
  position: relative;
  width: 60%;
  border: 6px solid #fff;
  border-radius: 3px;
}

.Watch__Shows__Kids .show__announcement__video video {
  display: table;
}

.Watch__Shows__Kids .show__announcement__video__controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Watch__Shows__Kids .show__announcement__video__controls a {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-out;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.Watch__Shows__Kids .show__announcement__video__controls:hover a,
.Watch__Shows__Kids .show__announcement__video__controls a:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.Watch__Shows__Kids .show__announcement__video__controls img {
  position: absolute;
  top: 50%;
  left: 55%;
  width: 14px;
  transform: translate(-50%, -50%);
}

.Watch__Shows__Kids .show__announcement__video > span {
  display: table !important;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas {
  position: absolute;
  width: 50%;
  transition: all 0.4s ease-in-out;
  pointer-events: none;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-top {
  top: -19.5%;
  right: 0%;
  width: 10%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-top img {
  width: 100%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-left {
  bottom: -15%;
  left: -24%;
  width: 48%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-left img {
  width: 40%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-right {
  right: -32%;
  bottom: -10%;
  width: 65%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-right img {
  position: relative;
  width: 33%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-right img:first-child {
  left: 6%;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-right img:nth-child(2) {
  z-index: 1;
}

.Watch__Shows__Kids .show__announcement__overlay .chillas-right img:nth-child(3) {
  right: 12%;
}

.Watch__Shows__Kids .show__announcement__overlay.announcement__playing .chillas-left {
  left: -41%;
}

.Watch__Shows__Kids .show__announcement__overlay.announcement__playing .chillas-right {
  right: -63.5%;
}

.Watch__Shows__Kids .Watch__Shows__Announcement--Label {
  margin-top: 24px;
  font-size: 15px;
  font-weight: 450;
  line-height: 1.4;
}

.SlidingTabBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px 0;
}

.SlidingTabBar__List {
  display: flex;
  position: relative;
  z-index: 10;
  border-radius: 99px;
  background-color: rgba(38, 38, 38, 0.6);
}

.SlidingTabBar input[type='radio'] {
  display: none;
}

.SlidingTabBar__List--Item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 42px;
  transition: color 0.15s ease-in;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.SlidingTabBar input[type='radio']:not(:checked) + label {
  color: #ffffff;
}
.SlidingTabBar input[type='radio']:checked + label {
  color: #2b2b2b;
}

.SlidingTabBar__List--Item--Label {
  z-index: 2;
  font-family: 'Libre Franklin';
  cursor: pointer;
  user-select: none;
}

.SlidingTabBar input[id='browse']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(0);
}
.SlidingTabBar input[id='daily-wire']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(100%);
}

.SlidingTabBar input[id='jordan-peterson']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(200%);
}

.SlidingTabBar input[id='prager-u']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(300%);
}

.SlidingTabBar input[id='movies']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(400%);
}

.SlidingTabBar input[id='kids']:checked ~ .SlidingTabBar__List--Glider {
  transform: translateX(500%);
}

.SlidingTabBar__List--Glider {
  display: flex;
  position: absolute;
  z-index: 1;
  width: 190px;
  height: 42px;
  transition: 0.25s ease-out;
  border-radius: 99px;
  background-color: #ffffff;
}

.Watch__HorizontalGradient {
  position: relative;
  width: 30%;
  width: 66em;
  max-width: 100%;
  height: 2px;
  height: 1px;
  margin: 18px 0;
  margin: 0 auto;
  margin: 0 auto;
  border: 0;
  opacity: 0.5;
  background-color: #e6e6e6;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0.02, #3a393d),
    color-stop(0.5, white),
    color-stop(0.98, #3a393d)
  );
}

.MobileSlidingTabBar {
  display: none;
}

@media (max-width: 1200px) {
  .Watch__Channels__List {
    flex-wrap: wrap;
    max-width: 700px;
  }
  .Watch__Channels__List span:nth-child(6) {
    display: none !important;
  }
  .SlidingTabBar__List--Item {
    width: 160px;
    font-size: 15px;
  }
  .SlidingTabBar__List--Glider {
    width: 160px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .version2-watchsection-container {
    padding: 0 16px;
  }
}

@media (max-width: 1020px) {
  .version2-watchsection-itemType-list .grid {
    margin-bottom: 0;
  }
  .version2-watchsection-item-container {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .SlidingTabBar__List--Item {
    width: 125px;
    font-size: 13px;
  }
  .SlidingTabBar__List--Glider {
    width: 125px;
  }
}

@media (max-width: 767px) {
  a.button-start-browsing {
    width: unset;
    height: unset;
    margin-top: 2rem;
    padding: 12px 20px;
    font-size: 20px;
  }
  .Watch__Shows__Top--Heading {
    font-size: 24px;
  }
  .Watch__Shows__Top--SubHeading {
    font-size: 16px;
  }
  .Watch__Shows__Kids .Watch__Shows__Announcement--Label {
    width: 52vw;
    margin-top: 15px;
    font-size: 14px;
  }
  .Watch__Banner {
    margin: 16px 0;
  }

  .SlidingTabBar {
    display: none;
    /* margin: 16px auto;
    width: 95%;
    overflow: auto;
    justify-content: flex-start; */
  }
  /* .SlidingTabBar__List--Item {
    width: 140px;
    font-size: 13px;
  }
  .SlidingTabBar__List--Glider {
    width: 140px;
  } */
  .version2-watchsection-itemType-list .grid {
    width: 100%;
  }
  .Watch__Shows {
    padding: 0px 30px;
  }
  .Watch__Shows__Top {
    margin-bottom: 30px;
  }
  .Watch__Channels {
    padding: 1rem 1rem 2rem;
    text-align: center;
  }
  .Watch__Channels__Logo {
    margin-top: 0;
  }
  .Watch__Channels__Logo span {
    width: 200px !important;
  }
  .Watch__Channels__List {
    grid-gap: 56px 4px;
    margin: 30px auto;
  }
  .Watch__Channels__List > span,
  .Watch__Channels__List > span img {
    width: 16px !important;
    height: 16px !important;
  }
  .Watch__Channels__List--Item span,
  .Watch__Channels__List--Item span > img {
    width: 25vw !important;
    height: 16vw !important;
    object-fit: cover;
  }
  .Watch__Channels__List--Item--Name {
    position: absolute;
    max-width: 75px;
    font-size: 12px;
    line-height: 1.2;
  }
  .version2-watchsection-container {
    padding: 1rem;
  }
  .version2-watchsection-itemType-heading {
    align-items: center;
  }
  .version2-watchsection-itemType-title {
    display: initial;
    max-width: 70%;
    font-size: 20px;
  }
  .version2-watchsection-itemType-live {
    width: max-content;
    margin: 10px 0 0;
  }

  .version2-videoListNav {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0.25rem 0 0.25rem;
  }
  .version2-videoListNav__List--Item {
    width: 33.33%;
    margin-right: 0;
    text-align: center;
  }
  .version2-videoListNav__List--Item:first-child,
  .version2-videoListNav__List--Item:nth-child(2),
  .version2-videoListNav__List--Item:nth-child(3) {
    margin-bottom: 0.5rem;
  }
  .version2-layout .menu-container .logo {
    width: 70vw;
    max-width: 250px;
  }
  .MobileSlidingTabBar {
    display: block;
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 50%;
    width: 70%;
    min-width: 260px;
    padding: 2rem 1.5rem 1.5rem;
    transform: translateX(-50%);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: #201f1f;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .MobileSlidingTabBar .MobileSlidingTabBar__List {
    display: flex;
    flex-direction: column;
  }
  .MobileSlidingTabBar .MobileSlidingTabBar__List--Headline {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Libre Franklin';
    font-size: 20px;
    line-height: 1;
  }
  .MobileSlidingTabBar .MobileSlidingTabBar__List--Headline span {
    left: -10px;
  }
  .MobileSlidingTabBar .MobileSlidingTabBar__List--Item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 1.5rem;
    border-radius: 30px;
    background-color: #fff;
    color: #2b2b2b;
    font-family: 'Libre Franklin';
    font-size: 18px;
  }
}

.Watch__bentkey {
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 660px;
  margin: 0 auto 48px;
  padding: 0 16px 50px;
  background-image: url(/images/bentkey/bg-desktop.jpg);
  background-repeat: no-repeat;
  background-position: -50vw center;
  background-size: auto 100%;
  color: #fff;
}

.Watch__bentkey .bk-btn-link {
  display: inline-block;
  width: 100%;
  max-width: 297px;
  padding: 18px;
  border: none;
  border-radius: 32px;
  outline: none;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.Watch__bentkey .Watch__bentkey-container {
  display: flex;
  align-items: end;
  height: 100%;
  padding: 0;
  background-image: url(/images/bentkey/chip-bug-mobile.png);
  background-repeat: no-repeat;
  background-position: calc(50% + 5px) 12%;
  text-align: center;
}

.Watch__bentkey .Watch__bentkey-content {
  width: 100%;
}

.Watch__bentkey .Watch__bentkey-content,
.Watch__bentkey .Watch__bentkey-logo {
  position: relative;
  z-index: 2;
}

.Watch__bentkey .Watch__bentkey-logo img {
  height: 50px;
  margin-bottom: 8px;
}

.Watch__bentkey .Watch__bentkey-content h2 {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 110%;
}

.Watch__bentkey .Watch__bentkey-content h2 span {
  display: block;
}

.Watch__bentkey .Watch__bentkey-content p {
  width: 232px;
  margin: 0 auto 32px;
  font-size: 15px;
  line-height: 150%;
}

@media screen and (min-width: 380px) {
  .Watch__bentkey .Watch__bentkey-container {
    background-position: calc(50% + 32px) 8%;
  }

  .Watch__bentkey .Watch__bentkey-content h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 500px) {
  .Watch__bentkey {
    background-position-x: left;
  }
}

@media screen and (min-width: 1000px) {
  .Watch__bentkey {
    height: 810px;
    margin-bottom: 0;
    padding: 133px 0 133px 10vw;
  }

  .Watch__bentkey .bk-btn-link {
    max-width: 208px;
  }

  .Watch__bentkey .Watch__bentkey-container {
    padding: 0 0 105px;
    background-image: url(/images/bentkey/chip-bug.png);
    background-repeat: no-repeat;
    background-position: 44vw;
    background-size: 44vw;
    text-align: start;
  }

  .Watch__bentkey .Watch__bentkey-content {
    padding-top: 32px;
  }

  .Watch__bentkey .Watch__bentkey-logo img {
    height: 114px;
    margin-bottom: 20px;
  }

  .Watch__bentkey .Watch__bentkey-content h2 {
    font-size: 36px;
  }

  .Watch__bentkey .Watch__bentkey-content p {
    width: 345px;
    margin: 0 0 32px;
    font-size: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .Watch__bentkey {
    padding-right: 40px;
    padding-left: 40px;
  }

  .Watch__bentkey::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background: linear-gradient(90deg, #141414 10%, rgba(0, 0, 0, 0));
  }

  .Watch__bentkey::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background: linear-gradient(-90deg, #141414 10%, rgba(0, 0, 0, 0));
  }

  .Watch__bentkey .Watch__bentkey-container {
    background-position: 92%;
    background-size: auto;
  }

  .Watch__bentkey .Watch__bentkey-content h2 {
    font-size: 40px;
  }
}

/* 
.lts-loading .video-container .join-to-watch,
.lts-loading .episode-container .join-to-watch {
  opacity: 0;
}
 */

.video-container .join-to-watch,
.episode-container .join-to-watch {
  transition-property: opacity;
  transition-duration: 220ms;
  transition-timing-function: ease-in-out;
  transition-delay: 150ms;
  opacity: 1;
}

.loading-spinner-container {
  display: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.lts-loading .loading-spinner-container {
  display: flex;
}

.loading-spinner-container .loading-spinner {
  width: 50px;
  height: 50px;
  margin: auto;
  animation: spinner 1200ms linear 0s infinite;
  border-right: 4px solid #b33828;
  border-bottom: 4px solid #b33828;
  border-radius: 50%;
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton-shimmer {
  animation: shimmer 2.5s infinite;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
}

.skeleton-title {
  width: 60%;
  min-width: 250px;
  min-height: 28px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
}
